import { ChildrenPreference, Currency, DatifyyUserPartnerPreferences, DrinkingPreference, GenderPreference, MaritalStatus, SmokingPreference } from "./types";

const emptyPartnerPreferences: DatifyyUserPartnerPreferences = {
  id: 0,
  genderPreference: GenderPreference.FEMALE,
  minAge: 18,
  maxAge: 50,
  minHeight: 150,
  maxHeight: 175,
  religion: null,
  educationLevel: [],
  profession: [],
  minIncome: 2,
  maxIncome: 100,
  currency: Currency.INR,
  locationPreference: null,
  smokingPreference: SmokingPreference.NO,
  drinkingPreference: DrinkingPreference.NO,
  maritalStatus: MaritalStatus.SINGLE,
  childrenPreference: ChildrenPreference.DOESNT_MATTER,
  religionPreference: null,
  ethnicityPreference: null,
  castePreference: null,
  partnerDescription: null,
  hobbies: [],
  interests: [],
  booksReading: [],
  music: [],
  movies: [],
  travel: [],
  sports: [],
  personalityTraits: [],
  relationshipGoals: null,
  lifestylePreference: null,
  locationPreferenceRadius: null,
  whatOtherPersonShouldKnow: null,
  activityLevel: null,
  petPreference: null,
  religionCompatibilityScore: null,
  incomeCompatibilityScore: null,
  educationCompatibilityScore: null,
  appearanceCompatibilityScore: null,
  personalityCompatibilityScore: null,
  valuesCompatibilityScore: null,
  matchingScore: null,
};

export default emptyPartnerPreferences;
